import i18n from "@/i18n";
import axios from "axios";
import swal from "sweetalert2";
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import store from "../store/index";

const swa = swal.mixin({
  customClass: { confirmButton: "btn btn-primary text-white" },
  buttonsStyling: false,
});

// Response interceptor
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const { status } = error.response;
    const { data } = error.response;
    let text = data.error;

    // http code 401
    if (status === 401) {
      let textError = i18n.global.t("error.token_invalid");

      if (text === "Expired JWT Token") {
        textError = i18n.global.t("error.token_expired");
      }

      toast(textError, {
        theme: "colored",
        type: 'error',
        position: "top-center",
        transition: "zoom",
        autoClose: 1500,
        hideProgressBar: false,
        dangerouslyHTMLString: true,
        limit: 1,
      });

      setTimeout(() => store.dispatch("auth/logout"), 2000);

      return Promise.reject(error);
    }

    if (status <= 300 || (status >= 400 && status < 500)) {
      return Promise.reject(error);
    }

    // forceError
    if ("forceError" in data) {
      return Promise.reject(data);
    }

    // http code 500 and database error
    if (
      status === 500 &&
      typeof data === "string" &&
      data.includes("database")
    ) {
      swa.fire({ icon: "error", text: data });

      location.reload();

      return new Promise(() => {});
    }

    // http code 503
    if (status === 503) {
      window.location = "/maintenance";

      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);
