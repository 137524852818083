<template>
  <button
    class="btn mb-0 d-flex justify-content-center align-items-center"
    :class="getClasses(variant, color, size, fullWidth, active)"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: "MaterialButton",

  props: {
    color: { type: String, default: "primary" },
    size: { type: String, default: "md" },
    variant: { type: String, default: "fill" },
    fullWidth: { type: Boolean, default: false },
    active: { type: Boolean, default: false },
  },

  methods: {
    getClasses: (variant, color, size, fullWidth, active) => {
      let colorValue, sizeValue, fullWidthValue, activeValue;

      // Setting the button variant and color
      if (variant === "gradient") {
        colorValue = `bg-gradient-${color}`;
      } else if (variant === "outline") {
        colorValue = `btn-outline-${color}`;
      } else {
        colorValue = `btn-${color}`;
      }

      sizeValue = size ? `btn-${size}` : '';
      fullWidthValue = fullWidth ? `w-100` : '';
      activeValue = active ? `active` : '';

      return `${colorValue} ${sizeValue} ${fullWidthValue} ${activeValue}`;
    },
  },
};
</script>
