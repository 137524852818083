const GlobalVariables = {
  install(app) {
    // app host
    app.config.globalProperties.$appHost = process.env.VUE_APP_APP_HOST;
    // app log level
    app.config.globalProperties.$debug = process.env.VUE_APP_LOG_LEVEL !== "error";
    // cloud host
    app.config.globalProperties.$cloudServerHost = process.env.VUE_APP_CLOUDSERVER_HOST;
    // currency
    app.config.globalProperties.$currencies = process.env.VUE_APP_CURRENCIES;
    // tax
    app.config.globalProperties.$tax = process.env.VUE_APP_TAX;
    // locales
    app.config.globalProperties.$locales = process.env.VUE_APP_APP_LOCALES.split("|");
    // types of numbers
    app.config.globalProperties.$typesNumber = [
      process.env.VUE_APP_TYPE_NUMBER_FAX,
      process.env.VUE_APP_TYPE_NUMBER_MOB,
      process.env.VUE_APP_TYPE_NUMBER_LANDLINE,
    ];
    // qrcode
    app.config.globalProperties.$qrcode = {
      width: parseInt(process.env.VUE_APP_QRCODE_WIDTH),
      height: parseInt(process.env.VUE_APP_QRCODE_HEIGHT),
    };
    // pagination
    app.config.globalProperties.$perPage = parseInt(process.env.VUE_APP_PER_PAGE);
    app.config.globalProperties.$perPageOptions = process.env.VUE_APP_PER_PAGE_OPTIONS.split(
      ","
    ).map((item) => parseInt(item));
    // cloud
    app.config.globalProperties.$port = process.env.VUE_APP_CLOUD_PORT;
    app.config.globalProperties.$noVNCPath = process.env.VUE_APP_NOVNC_PATH;
    // notification
    app.config.globalProperties.$notification = {
      timeClose: parseInt(process.env.VUE_APP_NOTIFICATION_TIME_CLOSE),
      horizontalAlign: process.env.VUE_APP_NOTIFICATION_HORIZONTAL_ALIGN,
      verticalAlign: process.env.VUE_APP_NOTIFICATION_VERTICAL_ALIGN,
    };
    // show password forgotten
    app.config.globalProperties.$showPasswordForgotten =
      process.env.VUE_APP_SHOW_PASSWORD_FORGOTTEN;
    // spinner
    app.config.globalProperties.$spinner = {
      color: process.env.VUE_APP_SPINNER_COLOR,
      size: parseInt(process.env.VUE_APP_SPINNER_SIZE),
    };
    // debounce
    app.config.globalProperties.$debounceTime = parseInt(process.env.VUE_APP_DEBOUNCE_TIME);
    app.config.globalProperties.$debounceTimeJS = parseInt(
      process.env.VUE_APP_DEBOUNCE_TIME_JS
    );
    // range date
    app.config.globalProperties.$dateRanges = process.env.VUE_APP_DATERANGES.split(",");
    // idle
    app.config.globalProperties.$idleTime = parseInt(process.env.VUE_APP_IDLE_TIME);
    // firewall rule
    app.config.globalProperties.$ruleDirectionTypes =
      process.env.VUE_APP_RULE_DIRECTION_TYPES.split(",");
    app.config.globalProperties.$ruleActions = process.env.VUE_APP_RULE_ACTIONS.split(",");
    app.config.globalProperties.$ruleProtocols =
      process.env.VUE_APP_RULE_PROTOCOLS.split(",");
    // payment discounts
    app.config.globalProperties.$discounts = JSON.parse(
      process.env.VUE_APP_PAYMENT_DISCOUNTS
    );
    // payment interval
    app.config.globalProperties.$paymentInterval = JSON.parse(
      process.env.VUE_APP_PAYMENT_INTERVAL
    );
    // default country code
    app.config.globalProperties.$defaultCountryCode =
      process.env.VUE_APP_COMPANY_COUNTRY_CODE;

    // cloud resources
    app.config.globalProperties.$resources = {
      cloud: {
        cpu_cores_min: parseInt(
          process.env.VUE_APP_CLOUDSERVER_DEFAULT_CPU_CORES_MIN
        ),
        cpu_cores_max: parseInt(
          process.env.VUE_APP_CLOUDSERVER_DEFAULT_CPU_CORES_MAX
        ),
        cpu_units: parseInt(process.env.VUE_APP_CLOUDSERVER_DEFAULT_CPU_UNITS),
        disk_size_min_gb: parseInt(
          process.env.VUE_APP_CLOUDSERVER_DEFAULT_DISK_SIZE_MIN_GB
        ),
        disk_size_max_gb: parseInt(
          process.env.VUE_APP_CLOUDSERVER_DEFAULT_DISK_SIZE_MAX_GB
        ),
        disk2_size_min_gb: parseInt(
          process.env.VUE_APP_CLOUDSERVER_DEFAULT_DISK2_SIZE_MIN_GB
        ),
        disk2_size_max_gb: parseInt(
          process.env.VUE_APP_CLOUDSERVER_DEFAULT_DISK2_SIZE_MAX_GB
        ),
        memory_size_min_gb: parseInt(
          process.env.VUE_APP_CLOUDSERVER_DEFAULT_MEMORY_SIZE_MIN_GB
        ),
        memory_size_max_gb: parseInt(
          process.env.VUE_APP_CLOUDSERVER_DEFAULT_MEMORY_SIZE_MAX_GB
        ),
        net_speed: parseInt(process.env.VUE_APP_CLOUDSERVER_DEFAULT_NET_SPEED),
        traffic: parseInt(process.env.VUE_APP_CLOUDSERVER_DEFAULT_TRAFFIC),
        ip4addresses: parseInt(
          process.env.VUE_APP_CLOUDSERVER_USER_LIMIT_IP4ADDRESSES
        ),
      },
    };

    // network prefixes
    app.config.globalProperties.$bitmask = {
      v4Min: parseInt(process.env.VUE_APP_BITMASKV4_MIN),
      v4Max: parseInt(process.env.VUE_APP_BITMASKV4_MAX),
      v6Min: parseInt(process.env.VUE_APP_BITMASKV6_MIN),
      v6Max: parseInt(process.env.VUE_APP_BITMASKV6_MAX),
    };

    // pusher
    app.config.globalProperties.$pusherData = {
      id: process.env.VUE_APP_PUSHER_APP_ID,
      key: process.env.VUE_APP_PUSHER_KEY,
      cluster: process.env.VUE_APP_PUSHER_CLUSTER,
    };

    // footer link
    app.config.globalProperties.$footerLink = process.env.VUE_APP_FOOTER_LINK;
    // check task is done interval
    app.config.globalProperties.$checkTaskInterval = process.env.VUE_APP_CHECK_TASK_INTERVAL;
    // pusher channel name for admin
    app.config.globalProperties.$pusherAdminChannel =
      process.env.VUE_APP_PUSHER_ADMIN_CHANNEL;
    // paypal client id
    app.config.globalProperties.$paypalClientId = process.env.VUE_APP_PAYPAL_CLIENT_ID;
    // paypal additional percent
    app.config.globalProperties.$paypalPercent = process.env.VUE_APP_PAYPAL_PERCENT;
    // number of days to check usage tables
    app.config.globalProperties.$usagePeriod = process.env.VUE_APP_CHECK_USAGE_PERIOD;
    // use blesta or ispworker
    app.config.globalProperties.$useBlesta =
      process.env.VUE_APP_USE_BLESTA === "true" ? true : false;
    // log lines
    app.config.globalProperties.$logLines = parseInt(process.env.VUE_APP_LOG_LINES);
  },
};

export default GlobalVariables;
