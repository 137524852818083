import "./assets/css/style.css";
import "./assets/js/nav-pills.js";
import "./assets/js/ripple-effect.js";
import "./assets/scss/material-dashboard.scss";

// A plugin file where you could register global variables
import { localize } from '@vee-validate/i18n';
import { required } from '@vee-validate/rules';
import { configure, defineRule } from 'vee-validate';

import GlobalComponents from "./globalComponents";
import GlobalVariables from "./globalVariables.js";
import GlobalVariablesRoles from "./globalVariablesRoles.js";

import de from "./locales/validate/de.json";
import en from "./locales/validate/en.json";

// Define the rule globally
defineRule('required', required);

configure({
  generateMessage: localize({
    en: { messages: en.messages },
    de: { messages: de.messages },
  }),
});

export default {
  install(app) {
    app.use(GlobalVariables);
    app.use(GlobalVariablesRoles);
    app.use(GlobalComponents);
  },
};
