import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

const state = {
  message: '',
  type: 'success',
};

const mutations = {
  /**
   * Set message
   * @param {*} state
   * @param {*} message
   * @param {*} type
   */
  setMessage(state, { message, type = 'success' }) {
    state.message = message;

    if (type) {
      state.type = type;
    }
  },
};

const actions = {
  /**
   * Set flash message
   * @param {*} commit
   * @param {*} message
   * @param {*} type
   */
  setFlashMessage({ commit }, { message, type = 'success' }) {
    commit("setMessage", { message, type });
  },

  /**
   * Show flash message
   * @param {*} commit
   */
  showFlashMessage({ commit }) {
    if (state.message) {
      toast(state.message, {
        theme: 'colored',
        type: state.type || 'success',
        position: 'top-center',
        transition: 'zoom',
        autoClose: 3500,
      });
    }

    commit("setMessage", { message: null });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
