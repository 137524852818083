import MaterialButton from "@/components/MaterialButton.vue";
import { Field, Form } from 'vee-validate';
import VueSelect from "vue-select";

const GlobalComponents = {
  install(app) {
    app.component("MdButton", MaterialButton);
    app.component("MdForm", Form);
    app.component("MdField", Field);
    app.component("MdSelect", VueSelect);
  },
};

export default GlobalComponents;
