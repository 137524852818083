const state = {
  lang: localStorage.getItem("lang") || "de",
};

const getters = {
  getLang: (state) => state.lang,
};

const mutations = {
  /**
   * Set language
   * @param {*} state
   * @param {*} lang
   */
  setLang(state, { lang }) {
    state.lang = lang;
    localStorage.setItem("lang", lang);
  },
};

const actions = {
  /**
   * Set language
   * @param {*} commit
   * @param {*} lang
   */
  setLang({ commit }, { lang }) {
    commit("setLang", { lang });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
