import de from "@/locales/de.json";
import en from "@/locales/en.json";
import { createI18n } from "vue-i18n";

// Create an instance of vue-i18n
const i18n = createI18n({
  // allowComposition: true,
  locale: 'de',
  fallbackLocale: 'de',
  messages: { en, de },
});

export default i18n;
