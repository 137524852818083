import GlobalVariablesRoles from "@/globalVariablesRoles.js";
import { createApp } from "vue";
const app = createApp({});
app.use(GlobalVariablesRoles);

const global = app.config.globalProperties;

import axios from "axios";
import cookie from "vue-cookies";

const state = {
  accessToken: cookie.get("access_token"),
  refreshToken: cookie.get("refresh_token"),
  user: null,
  redirectPath: null,
};

const getters = {
  getUser: (state) => {
    if (!state.user && state.accessToken) {
      try {
        const user = JSON.parse(
          window.atob(state.accessToken.split(".")[1].replace(/-/g, "+").replace(/_/g, "/"))
        );

        return user;
      } catch (e) {
        return null;
      }
    }

    return state.user;
  },
  getAccessToken: (state) => state.accessToken,
  getRefreshToken: (state) => state.refreshToken,
  hasUserId: (state) => state.user && state.user.id !== undefined,
  hasRole: (state) => (role) => state.user && state.user.roles.indexOf(role) !== -1,
  hasAdditionalUserRole: (state) =>
    state.user &&
    Object.values(global.$userRoles).some((role) => state.user.roles.indexOf(role) !== -1),
  isUser: (state) => state.user && state.user.roles.indexOf(global.$roles.user) !== -1,
  isResellerUser: (state) => state.user && state.user.roles.indexOf(global.$roles.reselleruser) !== -1,
  isAdmin: (state) => state.user && state.user.roles.indexOf(global.$roles.admin) !== -1,
  isPreviousAdmin: (state) => state.user && state.user.roles.indexOf(global.$roles.previousAdmin) !== -1,
  isLocked: (state) => state.user && +state.user.locked !== 1,
  isEnabled2FA: (state) => state.user && state.user.isEnabled2FA,
  showResellerManagement: (state) => state.user && state.user.showResellerManagement,
  hasDedicatedServer: (state) => state.user && state.user.hasDedicatedServer,
  hasRacks: (state) => state.user && state.user.hasRacks,
  isMainUserAccount: (state) => state.user && state.user.isMainUserAccount,
  showCloudMenu: (state) => state.user && state.user.showCloudMenu,
  showIsoManager: (state) => state.user && state.user.showIsoManager,
  hasCloudOptions: (state) => state.user && state.user.hasCloudOptions,
  allowExportCSV: (state) => state.user && state.user.allowExportCSV,
  allowFirewall: (state) => state.user && state.user.allowFirewall,
  allowApiAccess: (state) => state.user && state.user.allowApiAccess,
  allowExportBackup: (state) => state.user && state.user.allowExportBackup,
  allowTrafficNotification: (state) => state.user && state.user.allowTrafficNotification,
  allServersAreManaged: (state) => state.user && state.user.allServersAreManaged,
  redirectPath: (state) => state.redirectPath,
  hasHelpPage: (state) => state.user && state.user.hasHelpPage,
};

const mutations = {
  /**
   * Set access token
   * @param {*} state
   * @param {*} token
   */
  setAccessToken(state, { token }) {
    state.accessToken = token;
    // let expireTime = new Date(new Date().getTime() + 10 * 60 * 60 * 1000); //10 hr
    cookie.set("access_token", token, { expires: "1D", domain: location.hostname });
  },

  /**
   * Set refresh token
   * @param {*} state
   * @param {*} token
   */
  setRefreshToken(state, { token }) {
    state.refreshToken = token;
    cookie.set("refresh_token", token, { expires: "1D", domain: location.hostname });
  },

  /**
   * Set current user
   * @param {*} state
   * @param {*} user
   */
  setCurrentUser(state, { user }) {
    state.user = user;
  },

  /**
   * Set path for redirect page
   * @param {*} state
   * @param {*} redirectPath
   */
  setRedirectPath(state, { redirectPath }) {
    state.redirectPath = redirectPath;
  },

  /**
   * Logout
   */
  logout() {
    // clear cookie
    cookie.remove("access_token", null, location.hostname);
    cookie.remove("refresh_token", null, location.hostname);
    localStorage.removeItem("lastActiveTime");

    if (cookie.isKey("access_token") || cookie.isKey("refresh_token")) {
      cookie.remove("access_token");
      cookie.remove("refresh_token");
    }

    // redirect to login
    setTimeout(() => window.location = "/login", 500);
  },
};

const actions = {
  /**
   * Set access token
   * @param {*} commit
   * @param {*} token
   */
  setAccessToken({ commit }, { token }) {
    commit("setAccessToken", { token });
  },

  /**
   * Parse access token
   * @param {*} commit
   * @param {*} token
   */
  parseAccessToken({ commit }, { token }) {
    try {
      const data = JSON.parse(
        window.atob(token.split(".")[1].replace(/-/g, "+").replace(/_/g, "/"))
      );

      commit("setCurrentUser", { user: data });
    } catch (e) {
      commit("logout");
    }
  },

  /**
   * Set refresh token
   * @param {*} commit
   * @param {*} token
   */
  setRefreshToken({ commit }, { token }) {
    commit("setRefreshToken", { token });
  },

  /**
   * Set current user
   * @param {*} commit
   * @param {*} user
   */
  setCurrentUser({ commit }, { user }) {
    commit("setCurrentUser", { user });
  },

  /**
   * Set path for redirect
   * @param {*} commit
   * @param {*} redirectPath
   */
  setRedirectPath({ commit }, { redirectPath }) {
    commit("setRedirectPath", { redirectPath });
  },

  /**
   * Fail login
   * @param {*} commit
   */
  failLogin({ commit }) {
    commit("logout");
  },

  /**
   * Logout
   * @param {*} commit
   */
  async logout({ commit }) {
    try {
      // send logout
      await axios.get("/logout");
    } finally {
      commit("logout");
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
