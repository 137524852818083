const GlobalVariablesRoles = {
  install(app) {
    // roles
    app.config.globalProperties.$roles = {
      admin: process.env.VUE_APP_ROLE_ADMIN,
      reselleruser: process.env.VUE_APP_ROLE_RESELLER_USER,
      previousAdmin: process.env.VUE_APP_ROLE_PREVIOUS_ADMIN,
      user: process.env.VUE_APP_ROLE_USER,
      allowedToSwitch: process.env.VUE_APP_ROLE_ALLOWED_TO_SWITCH,
    };
    // user roles
    app.config.globalProperties.$userRoles = {
      none: "all",
      admin: process.env.VUE_APP_ROLE_ADMIN_C,
      tech: process.env.VUE_APP_ROLE_TECH_C,
      billing: process.env.VUE_APP_ROLE_BILLING_C,
    };
    app.config.globalProperties.$useBlesta = process.env.VUE_APP_USE_BLESTA === "true" ? true : false;
  },
};

export default GlobalVariablesRoles;
