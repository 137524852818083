const state = {
    isDarkMode: localStorage.getItem("isDarkMode") || '' + window.matchMedia("(prefers-color-scheme: dark)").matches,
    isPinned: true,
    links: [],
    paramId: null,
    server: null,
  };

  const getters = {
    isDarkMode: (state) => state.isDarkMode === 'true',
    links: (state) => state.links,
    paramId: (state) => state.paramId,
    serverData: (state) => state.server,
  };

  const mutations = {
    /**
     * Set server data
     * @param {*} state
     * @param {*} server
     */
    setServerData(state, { server }) {
      state.server = server;
    },

    /**
     * Toggle dark mode
     * @param {*} state
     * @param {*} isDarkMode
     */
    toggleDarkMode(state, { isDarkMode }) {
      state.isDarkMode = isDarkMode;
      localStorage.setItem("isDarkMode", isDarkMode);
    },

    /**
     * Minimize navbar
     * @param {*} state
     */
    navbarMinimize(state) {
      const sidenavShow = document.getElementsByClassName("g-sidenav-show")[0];

      if (sidenavShow.classList.contains("g-sidenav-pinned")) {
        sidenavShow.classList.remove("g-sidenav-pinned");
        sidenavShow.classList.add("g-sidenav-hidden");
        state.isPinned = true;

        return;
      }

      sidenavShow.classList.remove("g-sidenav-hidden");
      sidenavShow.classList.add("g-sidenav-pinned");
      state.isPinned = false;
    },

    /**
     * Set breadcrumb links
     * @param {*} state
     * @param {*} links
     * @param {*} id
     */
    setBreadcrumbLinks(state, { links, id }) {
      state.links = links;
      state.paramId = id;
    },
  };

  const actions = {
    /**
     * Set server data
     * @param {*} commit
     * @param {*} server
     */
    setServerData({ commit }, { server }) {
      commit("setServerData", { server });
    },

    /**
     * Toggle dark mode
     * @param {*} commit
     * @param {*} isDarkMode
     */
    toggleDarkMode({ commit }, { isDarkMode }) {
      commit("toggleDarkMode", { isDarkMode });
    },

    /**
     * Set breadcrumb links
     * @param {*} commit
     * @param {*} links
     * @param {*} id
     */
    setBreadcrumbLinks({ commit }, { links, id }) {
      commit("setBreadcrumbLinks", { links, id });
    },
  };

  export default {
    state,
    getters,
    actions,
    mutations
  };
