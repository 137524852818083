import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

export default {
  methods: {
    useShowNotification(message, type = "success", timeout = null) {
      toast(message, {
        theme: "colored",
        type: type,
        position: "top-center",
        transition: "zoom",
        autoClose: timeout ? timeout : this.$notification.timeClose,
        hideProgressBar: false,
        dangerouslyHTMLString: true,
        limit: 3,
      });
    },
  },
};
